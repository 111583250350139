import http from "@/api";
import jwt_decode from "jwt-decode";
import router from '../../router';

const state = {
  endpoints: {
    max: '/api/max/',
    config: '/api/config/full/',
    tr_: '/api/nationality/full/',
    trload: '/api/nationality/trload/',
    code: '/api/twofactor/sendcode/',
    profile: '/api/user/profile/',
    obtain: '/api/token/obtain/',
    refresh: '/api/token/refresh/',
    verify: '/api/token/verify/',
    emailcheck: '/api/user/check/email/',
    phonecheck: '/api/user/check/phone/',
  },
  refreshToken: localStorage.getItem('refreshToken'),
  accessToken: localStorage.getItem('accessToken'),
  config_: {},
  profile: {},
  tr_: {},
  max: {},
}

const mutations = {
  // Authentication
  ["LOGOUT"](state) {
    state.profile = {};
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    state.accessToken = "";
    state.refreshToken = "";
  },
  ["LOGIN"](state, { refresh, access }) {
    localStorage.setItem("refreshToken", refresh);
    localStorage.setItem("accessToken", access);
    http.defaults.headers.common['Authorization'] = 'Bearer ' + access;
    state.refreshToken = refresh;
    state.accessToken = access;
  },

  // User data
  ["PROFILE"](state, profile) {
    state.profile = profile;
  },

  // Translations
  ["SET_TR_"](state, tr_) {
    state.tr_ = tr_;
  },

  //Config
  ["SET_CONFIG"](state, config) {
    state.config_ = config;
  },

  ["MAX"](state, max) {
    state.max = max;
  },
};

const getters = {
  // Check if authenticate
  isAuthenticated: (state) => !!state.accessToken
};

const actions = {
  // Get config
  async config_({ commit, state }) {
    const response = await http.get(state.endpoints.config);
    commit('SET_CONFIG', response.data);
    return response;
  },
  // Get translations
  async tr_({ commit, state }) {
    const language = navigator.language.split('-')[1];
    const response = await http.get(state.endpoints.tr_, { params: { lang: language } });
    commit('SET_TR_', response.data);
    return response;
  },
  async trload({ state }, payload) {
    const response = await http.post(state.endpoints.trload, payload);
    return response;
  },

  // Authentication
  async getProfile({ commit, state }) {
    const response = await http.get(state.endpoints.profile);
    commit("PROFILE", response.data);
    return response;
  },
  async logout({ commit }) {
    commit("LOGOUT");
    router.push('/');
  },

  // Send code for 2F
  async codeUser({ state }, payload) {
    const response = await http.get(state.endpoints.code, payload);
    return response;
  },

  // Token
  async obtainToken({ commit, state }, playload) {
    const response = await http.post(state.endpoints.obtain, playload);
    commit("LOGIN", response.data);
    return response;
  },
  async refreshToken({ commit, state }) {
    try {
      const response = await http.post(state.endpoints.refresh, {
        token: state.accessToken,
        refresh: state.refreshToken,
      });
      commit("LOGIN", response.data);
      return response;
    } catch (error) {
      commit("LOGOUT");
      return error.response;
    }
  },
  async verifyToken({ commit, state }) {
    const token = state.accessToken;
    if (token) {
      try {
        await http.post(state.endpoints.verify, { token });
      } catch (e) {
        commit("LOGOUT");
        return false;
      }
      const decoded = jwt_decode(token);
      const now = Date.now() / 1000;
      const dif = decoded.exp - now;
      if (dif < 3580) {
        const response = await this.dispatch("User/refreshToken");
        if (response && response.status == 200) return true;
      } else if (dif) {
        return true;
      }
    }
    commit("LOGOUT");
    return false;
  },

  // Check email syntax and existence
  async emailCheck({ state }, payload) {
    const response = await http.get(state.endpoints.emailcheck, payload);
    return response;
  },
  // Check phone syntax and existence
  async phoneCheck({ state }, payload) {
    const response = await http.get(state.endpoints.phonecheck, payload);
    return response;
  },

  async max({ commit, state }) {
    const response = await http.get(state.endpoints.max);
    commit("MAX", response.data);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
