export default
  [
    {
      path: 'list/',
      name: 'company-list',
      component: () => import('@/views/company/List.vue')
    },
    {
      path: 'detail/:companyUid/',
      name: 'company-detail',
      component: () => import('@/views/company/Detail.vue')
    },
    {
      path: 'benchmark/',
      name: 'companies-benchmark',
      component: () => import('@/views/company/Benchmark.vue')
    },
    {
      path: 'esg-objectives/',
      name: 'esg-objectives',
      component: () => import('@/views/company/ESGObjectives.vue')
    },
  ];